<script>
  import ContactsUsModal from "./Modal/ContactsUsModal.svelte";
  import isModalOpen from '../context/modal';

  let isContactsUsModalOpen = false
  let windowWidth
  $: $isModalOpen = isContactsUsModalOpen

  function openModal () {
    windowWidth = window.innerWidth;
    console.log(windowWidth)
    isContactsUsModalOpen = true
    if (windowWidth <= 650) {
      document.body.style.overflow = "hidden";
    }
  }
  function closeModal () {
   isContactsUsModalOpen = false
   document.body.style.overflow = "";
  }
</script>

<div class="contact-with-us-wrapper">
  {#if !isContactsUsModalOpen}
    <div class="contact-with-us-btn-wrapper">
      <div class="contact-with-us-btn" on:click|preventDefault={openModal}>
        <img class="rocket-img" src="/development/bitrix24/rocket.png" alt="rocket">
        Свяжитесь с нами
      </div>
    </div>
  {/if}
  {#if isContactsUsModalOpen}
    <ContactsUsModal closeModal={closeModal} />
  {/if}
</div>

<style>.contact-with-us-btn-wrapper,.contact-with-us-wrapper{position:fixed;bottom:17px;left:60px;z-index:10}.contact-with-us-btn{position:relative;padding:15px 22px 17px 33px;font-size:15px;font-weight:600;line-height:23px;-webkit-backdrop-filter:blur(10px);backdrop-filter:blur(10px);background-color:rgba(84,84,96,.3);border-radius:10px;cursor:pointer}.contact-with-us-btn:hover{background-color:#333}.rocket-img{position:absolute;height:88px;top:-25px;left:-39px}@media(max-width:580px){.contact-with-us-btn-wrapper,.contact-with-us-wrapper{left:35px}}</style>
