<script>
  import { stores } from '@sapper/app'
  import TenderModal from './Modal/TenderModal.svelte'
  import isModalOpen from '../context/modal';

  let { page } = stores()

  $:isJobPage = $page.path.includes('/section/about/job')
  $:isCorePage = $page.path.includes('/section/about/core')

  let isTenderModal = false

  $: $isModalOpen = isTenderModal

  function closeModal () {
    isTenderModal = false
  }
</script>

<div class="footer {isJobPage ? `footer--color-black` : ''}">
  <div class="links">
    <a
    class="rating-link"
    rel="noreferrer"
    target="_blank"
    href="https://ratingruneta.ru/agency-k-r/"
    >
      {#if isJobPage || isCorePage}
        <img src="/images/black.png" width="120" height="120" alt="" />
      {:else}
        <img src="/images/white.png" width="120" height="120" alt="" />
      {/if}
    </a>
    <a href on:click|preventDefault={() => (isTenderModal = true)}>Пригласить в тендер</a>
    {#if !isJobPage}
      <a href="/section/about/job">Работа в KR</a>
    {/if}
    <a href="/section/about/core">Управление проектами<br />KR Core</a>
    <div class="links__wrapper">
      <a
        rel="noreferrer"
        target="_blank"
        href="https://vc.ru/u/1321652"
      >
        Блог
      </a>
    </div>
  </div>
  <div class="address">
    <p>Москва, Пресненская набережная, 12</p>
    <a href="tel:+74955447743">+7 495 544-77-43</a>
    <a
      rel="noreferrer"
      target="_blank"
      href="https://t.me/serj2mb"
    >
      Написать в телеграм
    </a>
  </div>
  <div class="copyright">
    <p>ООО «КP Цифровая мастерская»</p>
    <!-- <p>ISO 9000:10</p> -->
  </div>
  {#if isTenderModal}
    <TenderModal on:closeClick={closeModal} />
  {/if}
</div>

<style>.footer{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;font-weight:400;font-size:13px;line-height:1.615;color:var(--muted-text-color);padding-bottom:30px}.footer > div{text-align:right}.footer a{text-decoration:none;-webkit-transition:color .1s ease;transition:color .1s ease}.footer a:hover{color:#2be9b9}.links{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;font-weight:400}.links > *{margin-bottom:18px}.rating-link:hover{-webkit-transform:scale(1.05);transform:scale(1.05)}.address{margin-bottom:23px}.address,.copyright{color:var(--strong-muted-text-color)}.address p,.copyright p{margin:0}.address a,.copyright a{color:var(--strong-muted-text-color)}.address p,.copyright p{margin-bottom:7px}.copyright p:last-child{margin-bottom:0}@media (max-width:768px){.rating-link img{width:90px;height:auto}}@media (max-width:480px){.footer{display:block;font-size:15px;line-height:1.6666;padding-top:30px;padding-bottom:30px}.footer > div{text-align:left}.links{position:relative;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start}.links > *{margin-bottom:10px}.rating-link{position:absolute;top:0;right:0}.address{margin-bottom:10px}.address p,.copyright p{margin-bottom:4px}.copyright p:last-child{margin-bottom:0}}</style>
