<script>
  import Logo from './Logo.svelte'
  import Menu from './Menu.svelte'
  import NewMenu from './NewMenu.svelte';
  import { stores } from '@sapper/app'
  import { getMenuTree } from '../../util'

  export let menu

  const { page } = stores()

  let displayMenu = []

  page.subscribe(({ params }) => {
    try {
      if (params.section) {
        const section = menu.find((s) => s.id === params.section)
        displayMenu = getMenuTree(menu, section)
      }
    } catch (e) {}
  })
</script>

<div class="root {$page.path === `/` ? 'root--no-padding' : ``}">
  <div>
    {#if $page.path !== '/'}
      <nav>
          <NewMenu history={displayMenu[displayMenu.length - 2] || null} isCurrent={displayMenu.length > 1} items={displayMenu[displayMenu.length - 1]} />
      </nav>
    {/if}
  </div>

  <a class="p1 p1--2020" target="_blank" rel="noopener" href="https://2020.pioneum.ru/2020/kr-digital"><img alt="p1" src="/images/excellent.png" /></a>
  <a class="p1 p1--2021" target="_blank" rel="noopener" href="https://www.pioneum.ru/2021/kr-digital"><img alt="p1" src="/images/excellent2021.png" /></a>
</div>

<style>.root{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;padding-bottom:20px;min-height:60px;position:relative}.root--no-padding{min-height:20px;padding:0}.root nav{padding-right:280px}.logo-container{display:none;position:relative}.logo{display:inline-block;line-height:0;-webkit-transform:translateY(-2px);transform:translateY(-2px)}.p1{position:absolute;top:-35px;right:150px}.p1 img{width:120px}.p1--2021{right:0}@media(max-width:768px){.root nav{padding-right:200px}.p1{right:90px;top:-35px}.p1 img{width:100px}.p1--2021{right:-20px}}@media (max-width:600px){.root{-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start;padding-bottom:20px}.logo-container{display:block;-webkit-box-ordinal-group:0;-ms-flex-order:-1;order:-1;white-space:nowrap}.logo{vertical-align:text-bottom}.logo-container.has-slash::after{content:"/";margin:0 5px;color:var(--menu-color);font-size:15px}.root nav{padding-right:0}.p1{right:70px;top:-60px}.p1 img{width:80px}.p1--2021{right:-20px}}</style>
